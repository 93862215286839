body,
html {
  padding: 0px;
  margin: 0px;
  font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  /* font-size: 1.6rem; */
  color: rgb(237, 237, 239);
  background: url("./images/background.jpg") #f3f3f3;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;

  @media only screen and (min-width: 768px) {
    font-size: 15px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 15px;
  }
}

*:focus {
  outline: none;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

span {
  display: inline;
}

strong {
  font-weight: 800;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1300px;
  }
}
